'use client';
import { useEffect } from 'react';

import { currentVehicleAtom } from '@core/Atoms/Vehicle/CurrentVehicle.atom';
import { FavouritesButton } from '@core/Components/FavouritesButton/FavouritesButton';
import { generateSrcSet } from '@core/Components/HeycarImage/GenerateSrcSet';
import { HeycarImage } from '@core/Components/HeycarImage/HeycarImage';
import { kontentLoader } from '@core/Components/KenticoImage/KontentLoader';
import { ShareButton } from '@core/Components/ShareButton/ShareButton';
import { PAGE_TYPE } from '@core/Constants/PageType';
import { debounce } from '@core/Utils/Debounce';
import { Chip } from '@gds/Chip/Chip';
import { useAtomValue } from 'jotai';
import Image from 'next/image';

import { heycarLoader } from 'Components/ClientWrappers/HeycarLoader';

import RacLogo from '../../../../web/public/img/rac.webp';

import { IMAGE_SIZES } from './ImageCarousel.constants';

import { ImageCarouselProps } from './ImageCarousel.entity';

import ImageCarouselDots from './ImageCarouselDots/ImageCarouselDots';

import styles from './ImageCarousel.module.css';

export const ImageCarousel = ({
  images,
  imageOnClick,
  positionIndex,
  index,
  strings,
  visibleOverflowView,
  shouldShowDotCarousel = true,
  size = 'large',
  carouselProps,
  hasReservedOrInactiveStatus = false,
  shouldShowFavIcon = false,
  shouldShowShare = false,
  isRacApproved = false,
  isPdp = false,
  tenant,
  locale,
}: ImageCarouselProps) => {
  const totalImgs = images.length;
  const hasMultipleImages = totalImgs > 1;
  const vehicle = useAtomValue(currentVehicleAtom)!;

  const { currentIndex, dragStart, dragging, dragEnd, moveTo, fromIndex, toIndex, carouselRef } =
    carouselProps;

  let lastWindowWidth = typeof window === 'undefined' ? 0 : window.innerWidth;

  function handleResize() {
    const windowWidth = typeof window === 'undefined' ? 0 : window.innerWidth;
    const threshold = 1;
    if (Math.abs(windowWidth - lastWindowWidth) > threshold) {
      lastWindowWidth = windowWidth;
      debounce(() => moveTo(currentIndex), 500)();
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [moveTo, currentIndex]);

  useEffect(() => {
    if (typeof positionIndex === 'number') moveTo(positionIndex);
  }, [positionIndex]);
  const isInActiveVehicle = vehicle?.details?.status === 'inactive';

  return (
    <section
      className={`${styles.wrapper} ${visibleOverflowView ? styles.visibleOverflow : ''}`}
      role="group"
      aria-roledescription="carousel"
      aria-label={strings.carouselLabel}
      data-test-id="image-carousel"
      onTouchStart={e => dragStart(e)}
      onTouchMove={e => dragging(e)}
      onTouchEnd={dragEnd}
    >
      {hasReservedOrInactiveStatus && (
        <Chip className={styles.reservedChip}>
          {isInActiveVehicle ? strings.inActiveChipLabel : strings.reservedChipLabel}
        </Chip>
      )}

      {shouldShowShare && (
        <div className={`${styles.btn} ${styles.shareBtn}`}>
          <ShareButton locale={locale} />
        </div>
      )}

      {shouldShowFavIcon && !hasReservedOrInactiveStatus && (
        <div className={`${styles.btn} ${styles.favBtn}`}>
          <FavouritesButton
            genericVehicleListings={vehicle}
            pageType={PAGE_TYPE.Pdp}
            tenant={tenant}
          />
        </div>
      )}
      <div
        className={styles.images}
        data-reservedstatus={hasReservedOrInactiveStatus}
        data-test-id="image-carousel-images"
        ref={carouselRef}
      >
        {currentIndex !== 0 &&
          images.map((image, i) => {
            const loadr = image.isKontent ? kontentLoader : heycarLoader;
            if (i <= currentIndex + 1)
              return (
                <link
                  key={`${image.url}-${i + 1}`}
                  rel="preload"
                  as="image"
                  fetchPriority="low"
                  imageSrcSet={generateSrcSet(image.url, loadr)}
                  sizes={IMAGE_SIZES[size]}
                />
              );
          })}

        {images.map((image, i) => {
          const loadr = image.isKontent ? kontentLoader : heycarLoader;
          if (i <= currentIndex + 1)
            return (
              <div
                key={`${image.url}-${i + 1}`}
                className={`${styles.imageSlide}`}
                role="group"
                aria-label={`${strings.slide} ${i + 1} ${strings.of} ${totalImgs}`}
                onClick={() => imageOnClick && imageOnClick(i)}
              >
                <HeycarImage
                  src={image.url}
                  alt={image.altText || ''}
                  className={styles.image}
                  sizes={IMAGE_SIZES[size]}
                  loader={loadr}
                  forceVisible={(index ?? 100) <= 1 && i === 0}
                  preload={i <= 1}
                />
              </div>
            );
        })}
      </div>
      {isRacApproved && (
        <aside className={styles.racBadge}>
          <Image
            src={RacLogo}
            width={isPdp ? 105 : 70}
            height={isPdp ? 105 : 70}
            alt="rac approved logo"
          />
        </aside>
      )}
      {hasMultipleImages && !hasReservedOrInactiveStatus && (
        <ImageCarouselDots
          strings={strings}
          moveTo={moveTo}
          totalImgs={totalImgs}
          currentIndex={currentIndex}
          fromIndex={fromIndex}
          toIndex={toIndex}
          size={size}
          shouldShowDotCarousel={shouldShowDotCarousel}
        />
      )}
    </section>
  );
};
