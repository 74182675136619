'use client';
import React, { useEffect, useState } from 'react';

import { useAtomValue } from 'jotai';

import { formStateAtom } from '@core/Atoms/Pdp/FormState.atom';
import { searchParamsAtom } from '@core/Atoms/SearchParams/SearchParams.atom';
import { userIdAtom } from '@core/Atoms/User/UserId.atom';
import { isWindowDefined } from '@core/Constants/Window';
import { Share } from '@gds/Icons/Paths/Share';
import { Typography } from '@gds/Typography/Typography';
import { Locale } from '@schema-markup/types';
import { trackCustomEvent } from '@tracking/Utils/TrackCustomEvent';

import { generateShareButtonTracking } from './ShareButton.tracking';

import styles from './ShareButton.module.css';

const NOTIF_TIMEOUT = 3000;

export const ShareButton = ({ locale }: { locale: Locale }) => {
  const userId = useAtomValue(userIdAtom);
  const searchParams = useAtomValue(searchParamsAtom);
  const formState = useAtomValue(formStateAtom);
  const [showNotif, setShowNotif] = useState(false);
  const [hasShareFn, setHasShareFn] = useState(false);
  const [copyFn, setCopyFn] = useState(false);
  const currentPageUrl = isWindowDefined ? window.location.href : '';
  const shareUrl = currentPageUrl ? new URL(currentPageUrl) : '';

  if (typeof shareUrl === 'object') {
    shareUrl.searchParams.append('utm_source', 'pdp_share');
  }

  useEffect(() => {
    //Linter says "condition will always return true since this function is always defined"
    //But that is not true
    // @ts-ignore
    if (window.navigator?.share) {
      setHasShareFn(true);
    }
    if (window.navigator?.clipboard) {
      setCopyFn(true);
    }
  }, []);

  if (!hasShareFn && !copyFn) return null;

  const trackingFunction = () => {
    const label = hasShareFn ? 'share' : 'share not supported';

    const { event, context } = generateShareButtonTracking({
      searchParams,
      userId,
      formState,
      ctaType: 'button',
      ctaWording: label,
      ctaUrl: shareUrl.toString(),
    });

    trackCustomEvent({ event, context });
  };

  const preventClick = (e: React.MouseEvent<HTMLDivElement | HTMLOrSVGElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleClick = () => {
    if (hasShareFn) {
      navigator?.share({
        url: currentPageUrl,
      });
    } else if (copyFn) {
      navigator?.clipboard.writeText(currentPageUrl);
      setShowNotif(true);
    }

    trackingFunction();
  };

  const hideNotif = () => {
    setTimeout(() => {
      setShowNotif(false);
    }, NOTIF_TIMEOUT);
  };

  return (
    <>
      <Typography
        className={`${styles.notif} ${showNotif ? styles.show : ''}`}
        Component="p"
        onTransitionEnd={() => hideNotif()}
        variant="caption1"
      >
        {locale === 'fr' ? 'copié dans le presse-papier' : 'copied to clipboard'}
      </Typography>
      <div onClick={preventClick} data-test-id="share-button">
        <Share onClick={handleClick} />
      </div>
    </>
  );
};
