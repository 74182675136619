'use client';

import { useRef } from 'react';

import { Locale } from '@core/Entities/Locale/Locale.entity';
import { VehicleImage } from '@core/Entities/Vehicle/VehicleImage.entity';

import { useImageCarousel } from 'Components/ImageCarousel/Hooks/UseImageCarousel';
import { ImageCarousel } from 'Components/ImageCarousel/ImageCarousel';

export const ImageCarouselWrapper = ({
  images,
  size = 'small',
  strings,
  index,
  isRacApproved,
  locale,
}: {
  images: VehicleImage[];
  size?: 'small' | 'large';
  strings: Record<string, string>;
  index?: number;
  isRacApproved?: boolean;
  locale: Locale;
}) => {
  const carouselRef = useRef<HTMLDivElement>(null);

  const { currentIndex, dragStart, dragging, dragEnd, moveTo, fromIndex, toIndex } =
    useImageCarousel(carouselRef);

  const carouselProps = {
    currentIndex,
    dragStart,
    dragging,
    dragEnd,
    moveTo,
    fromIndex,
    toIndex,
    carouselRef,
  };

  return (
    <ImageCarousel
      size={size}
      images={images}
      strings={strings}
      index={index}
      carouselProps={carouselProps}
      isRacApproved={isRacApproved}
      locale={locale}
    />
  );
};
