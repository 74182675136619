'use client';

import { FC, useEffect, useState } from 'react';

import { formStateAtom } from '@core/Atoms/Pdp/FormState.atom';
import {
  SearchParamsType,
  searchParamsAtom,
  searchParamsValueAtom,
} from '@core/Atoms/SearchParams/SearchParams.atom';
import { isWindowDefined } from '@core/Constants/Window';
import { filterObjectProperties } from '@core/Utils/Object/FilterObjectProperties';
import { trackPageView } from '@snowplow/browser-tracker';

import { TrackingEvent } from '@tracking/Entities/Schema.entity';
import { marketingToEvent } from '@tracking/Schemas/Marketing/MarketingToEvent';
import { userContextToEvent } from '@tracking/Schemas/UserContext/UserContextToEvent';
import { getSchemaByRef } from '@tracking/Utils/GetSchemaByRef';
import { validateSchema } from '@tracking/Utils/ValidateSchema';
import { useAtomValue, useSetAtom } from 'jotai';
import hash from 'stable-hash';

import { userIdAtom } from 'Atoms/User/UserId.atom';

const marketingIdsToCheck: (keyof SearchParamsType)[] = [
  'fbc',
  'fbclid',
  'gclid',
  'msclkid',
  'ttclid',
  'ad_id',
  'OutbrainClickId',
  'ScCid',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'cItemId',
  'msclkId',
];

export const TrackPage: FC<{
  context?: TrackingEvent[];
  searchParams?: SearchParamsType;
}> = ({ context = [], searchParams }) => {
  const [hasTrackViewPage, setTrackViewPage] = useState(false);

  const searchParamsValues = useSetAtom(searchParamsValueAtom);
  const userId = useAtomValue(userIdAtom);
  const formState = useAtomValue(formStateAtom);
  const searchParamsAtomValue = useAtomValue(searchParamsAtom);

  let newContext = [...context];

  useEffect(() => {
    const routeSearchParams = filterObjectProperties(
      searchParams as SearchParamsType,
      marketingIdsToCheck,
    );
    const mergedObject = { ...searchParamsAtomValue, ...routeSearchParams };
    searchParamsValues(mergedObject);
  }, [
    hash(searchParamsAtom),
    hash(filterObjectProperties(searchParams as SearchParamsType, marketingIdsToCheck)),
  ]);

  useEffect(() => {
    if (searchParamsAtomValue) {
      setTrackViewPage(true);
    }
  }, [searchParamsAtomValue]);

  useEffect(() => {
    if (hasTrackViewPage) {
      const marketingDataObj = {
        marketingValues: { ...searchParamsAtomValue },
      };
      const marketingEvent = marketingToEvent(marketingDataObj);
      const userData = {
        userId,
        values: { ...formState },
      };
      const userEvent = userContextToEvent(userData);
      newContext = [...context, userEvent, marketingEvent];

      newContext?.map(event => validateSchema(getSchemaByRef(event.schema), event));
      trackPageView({ context: newContext });

      const eventObject = { event: 'pageview', data: newContext };

      // @ts-expect-error - window typing
      if (isWindowDefined) (window.dataLayer ||= []).push(eventObject);
      // @ts-expect-error - window typing
      if (isWindowDefined) (window.eventHistory ||= []).push(eventObject);
    }
  }, [hasTrackViewPage]);

  if (typeof window === 'undefined') return null;
};
