import { Button } from '@gds/Button/Button';
import { ChevronLeft } from '@gds/Icons/Paths/ChevronLeft';
import { ChevronRight } from '@gds/Icons/Paths/ChevronRight';
import clamp from 'lodash.clamp';
import range from 'lodash.range';

import { DOT_DIAMETER_LARGE, DOT_DIAMETER_SMALL } from '../ImageCarousel.constants';
import { ImageCarouselDotProps } from '../ImageCarousel.entity';

import styles from './ImageCarouselDots.module.css';

const scaleValueForIndex = (index: number) =>
  clamp(1 + (2 / 15) * index - (1 / 15) * index * index, 0, 1);

const USE_LARGE_DOTS = ['large', 'review'];

const ImageCarouselDots = ({
  strings,
  moveTo,
  totalImgs,
  currentIndex,
  fromIndex,
  toIndex,
  size,
  shouldShowDotCarousel,
}: ImageCarouselDotProps) => {
  const isLargeDots = USE_LARGE_DOTS.includes(size || '');
  const NB_OF_DOTS = totalImgs;
  const DOT_DIAMETER = isLargeDots ? DOT_DIAMETER_LARGE : DOT_DIAMETER_SMALL;
  const visibleRange = range(Math.max(0, fromIndex - 2), Math.min(toIndex + 3, NB_OF_DOTS));

  const visibleWidth =
    visibleRange.reduce(
      (previous, index) => previous + DOT_DIAMETER * scaleValueForIndex(index - fromIndex),
      0,
    ) +
    (visibleRange.length - 1) * DOT_DIAMETER;

  const getTranslateXVal = (index: number) => {
    return (
      range(0, index).reduce(
        (previous, index) =>
          previous + DOT_DIAMETER * scaleValueForIndex(index - fromIndex) + DOT_DIAMETER,
        0,
      ) -
      Math.max(0, fromIndex - 2) * DOT_DIAMETER -
      visibleWidth / 2
    );
  };

  return (
    <div
      role="group"
      aria-label={strings.controlsLabel}
      className={styles.controls}
      onClick={event => event.preventDefault()}
      tabIndex={0}
      data-variant={size}
    >
      <Button
        aria-label={strings.prevBtnLabel}
        className={styles.caroBtn}
        data-test-id="image-carousel-prev-btn"
        disabled={currentIndex === 0}
        onClick={() => moveTo(currentIndex - 1, 'prev')}
        leftIcon={<ChevronLeft />}
        size="small"
      />
      {shouldShowDotCarousel && (
        <div className={styles.dots}>
          {range(NB_OF_DOTS).map(index => {
            const scale = scaleValueForIndex(index - fromIndex);
            const translateX = getTranslateXVal(index) + 4;

            const transform = {
              '--transform': `translateX(${translateX}px) scale(${scale})`,
            } as React.CSSProperties;

            if (index <= currentIndex + 3)
              return (
                <div
                  key={index}
                  data-test-id="image-carousel-dot"
                  className={`${styles.dot} ${currentIndex === index ? styles.dotActive : ''} ${
                    !isLargeDots ? styles.smallDots : styles.largerDots
                  }`}
                  style={transform}
                />
              );
          })}
        </div>
      )}
      <Button
        aria-label={strings.nextBtnLabel}
        className={styles.caroBtn}
        data-test-id="image-carousel-next-btn"
        disabled={currentIndex === NB_OF_DOTS - 1}
        onClick={() => moveTo(currentIndex + 1, 'next')}
        leftIcon={<ChevronRight />}
        size="small"
      />
    </div>
  );
};

export default ImageCarouselDots;
