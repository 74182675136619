import { RefObject, TouchEvent, useRef, useState } from 'react';

import { DEFAULT_TO_INDEX_DOTS, IMG_SWIPE_THRESH } from '../ImageCarousel.constants';

export const useImageCarousel = (carousel: RefObject<HTMLDivElement>) => {
  const isDragStart = useRef(false);
  const isDragging = useRef(false);
  const isVerticalScroll = useRef(false);
  const isHorizontalScroll = useRef(false);
  const prevPageX = useRef(0);
  const prevPageY = useRef(0);
  const prevScrollLeft = useRef(0);
  const posDiff = useRef(0);
  const [[fromIndex, currentIndex, toIndex], setIndexes] = useState([0, 0, DEFAULT_TO_INDEX_DOTS]);

  const getImgW = () => {
    const caro = carousel.current;

    if (!caro) return 0;

    const img = caro.children[0].children[0];

    return img ? img.clientWidth : caro.clientWidth;
  };

  const closestSlide = (pos: number, slideW: number, fwd = true) => {
    const threshold = IMG_SWIPE_THRESH / 2;
    const thresholdDistance = parseInt((slideW / threshold).toString(), 10);
    const posAndThresh = pos + thresholdDistance;

    // Check if the distance swiped is greater than thresholdDistance
    if (Math.abs(posAndThresh - (posAndThresh % slideW)) > thresholdDistance) {
      if (fwd) {
        return Math.ceil(posAndThresh / slideW) * slideW;
      } else {
        return Math.floor(posAndThresh / slideW) * slideW;
      }
    }

    // handle first slide
    if (Math.abs(posAndThresh - (posAndThresh % slideW)) === 0) {
      console.log('zero detected');
      if (fwd) {
        return slideW;
      } else {
        return 0;
      }
    }

    return posAndThresh - (posAndThresh % slideW);
  };

  const moveTo = (positionIndex: number, direction?: string) => {
    const caro = carousel.current;

    if (!caro || typeof positionIndex !== 'number') return;

    const imgW = getImgW();
    // Set new position
    caro.scrollLeft = positionIndex * imgW;

    // use case for mounting phase of Imagecarousel and resize events
    if (!direction) {
      if (positionIndex >= DEFAULT_TO_INDEX_DOTS) {
        setIndexes([positionIndex - DEFAULT_TO_INDEX_DOTS, positionIndex, positionIndex]);
      } else {
        setIndexes([fromIndex, positionIndex, toIndex]);
      }
    }

    if (direction === 'prev') {
      setIndexes(([fromIndex, currentIndex, toIndex]) => {
        if (currentIndex === fromIndex) {
          return [currentIndex - 1, currentIndex - 1, currentIndex + 1];
        }

        return [fromIndex, currentIndex - 1, toIndex];
      });
    }
    if (direction === 'next') {
      setIndexes(([fromIndex, currentIndex, toIndex]) => {
        if (currentIndex === toIndex) {
          return [currentIndex - 1, currentIndex + 1, currentIndex + 1];
        }
        return [fromIndex, currentIndex + 1, toIndex];
      });
    }
  };

  const autoSlide = () => {
    const caro = carousel.current;
    const scrollLeft = caro?.scrollLeft;

    if (
      !caro ||
      scrollLeft === undefined ||
      scrollLeft === (caro?.scrollWidth || 0) - (caro?.clientWidth || 0)
    )
      return;

    const imgW = getImgW();
    const isFwd = scrollLeft > prevScrollLeft.current;
    const newPos = closestSlide(caro.scrollLeft, imgW, isFwd);

    // Set new position
    caro.scrollLeft = newPos;

    const newCurrentIndex = newPos / imgW;

    if (isFwd) {
      setIndexes(([fromIndex, currentIndex, toIndex]) => {
        if (currentIndex === toIndex) {
          return [currentIndex - 1, newCurrentIndex, currentIndex + 1];
        }

        return [fromIndex, newCurrentIndex, toIndex];
      });
    } else {
      setIndexes(([fromIndex, currentIndex, toIndex]) => {
        if (currentIndex === fromIndex) {
          return [currentIndex - 1, newCurrentIndex, currentIndex + 1];
        }

        return [fromIndex, newCurrentIndex, toIndex];
      });
    }
  };

  const dragStart = (e: TouchEvent<HTMLElement>) => {
    isDragStart.current = true;
    prevPageX.current = e.touches[0].pageX;
    prevPageY.current = e.touches[0].pageY;
    prevScrollLeft.current = carousel.current?.scrollLeft || 0;
    isVerticalScroll.current = false;
    isHorizontalScroll.current = false;
  };

  const dragging = (e: TouchEvent<HTMLElement>) => {
    if (!isDragStart.current || !carousel.current) return;

    isDragging.current = true;
    carousel.current.classList.add('dragging');

    if (
      Math.abs(e.touches[0].pageY - prevPageY.current) >
      Math.abs(e.touches[0].pageX - prevPageX.current)
    ) {
      isVerticalScroll.current = true;
    } else {
      if (isVerticalScroll.current !== true) {
        isHorizontalScroll.current = true;
      }
    }

    if (isVerticalScroll.current && !isHorizontalScroll.current) {
      e.stopPropagation();
      return;
    }

    posDiff.current = e.touches[0].pageX - prevPageX.current;
    carousel.current.scrollLeft = (prevScrollLeft.current || 0) - (posDiff.current || 0);
  };

  const dragEnd = () => {
    isDragStart.current = false;
    isVerticalScroll.current = false;
    isHorizontalScroll.current = false;
    carousel.current?.classList.remove('dragging');

    if (!isDragging.current) return;
    isDragging.current = false;
    autoSlide();
  };

  return {
    currentIndex,
    fromIndex,
    toIndex,
    dragStart,
    dragging,
    dragEnd,
    moveTo,
    closestSlide,
  };
};
