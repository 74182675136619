'use client';

import { atom } from 'jotai';

import { atomWithStorage } from 'jotai/utils';
import Cookies from 'js-cookie';

const cookieStorage = {
  getItem: (key: string) => {
    const cookieValue = Cookies.get(key);
    return cookieValue ? JSON.parse(cookieValue) : null;
  },
  setItem: (key: string, value: string[]) => Cookies.set(key, JSON.stringify(value)),
  removeItem: (key: string) => Cookies.remove(key),
};

export const favouritesAtom = atomWithStorage(
  `favourites-${Cookies.get('locale')}`,
  [],
  cookieStorage,
);

export const removeFromFavouritesAtom = atom(null, (_, set, heycarId) => {
  set(favouritesAtom, (currentFavourites: string[]) =>
    currentFavourites.filter((vehicleId: string) => vehicleId !== heycarId),
  );
});

export const addToFavouritesAtom = atom(null, (_, set, favData: { heycarId: string }) => {
  set(favouritesAtom, (currentFavourites: string[]) => [
    ...(currentFavourites || []),
    favData.heycarId,
  ]);
});
