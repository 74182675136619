export const PAGE_TYPE = {
  Home: 'home',
  Plp: 'plp',
  Pdp: 'pdp',
  Guides: 'Guides',
  Review: 'review',
  Blog: 'Blog',
  Dealerships: 'dealerships',
  Favourites: 'favourites',
  UniversalPage: 'universal_page',
  Leasing: 'leasing',
};
