import { LeadFormState } from '@core/Atoms/Pdp/FormState.atom';
import { SearchParamsType } from '@core/Atoms/SearchParams/SearchParams.atom';
import { ctaClickedToEvent } from '@tracking/Schemas/CtaClicked/CtaClickedToEvent';
import { marketingToEvent } from '@tracking/Schemas/Marketing/MarketingToEvent';
import { userContextToEvent } from '@tracking/Schemas/UserContext/UserContextToEvent';

interface Props {
  searchParams: SearchParamsType;
  userId: string | null | undefined;
  formState: LeadFormState;
  ctaType: string;
  ctaWording: string;
  ctaUrl: string;
}

export function generateShareButtonTracking({
  searchParams,
  userId,
  formState,
  ctaType,
  ctaWording,
  ctaUrl,
}: Props) {
  const marketingDataObj = {
    marketingValues: { ...(searchParams ?? {}) },
  };

  const userData = {
    userId,
    values: { ...formState },
  };
  const ctaEventObj = {
    ctaType,
    ctaWording,
    targetUrl: ctaUrl,
  };

  // event actions
  const ctaEvent = ctaClickedToEvent(ctaEventObj);

  // event entities
  const userEvent = userContextToEvent(userData);
  const marketingEvent = marketingToEvent(marketingDataObj);

  const context = [userEvent, marketingEvent];

  return { event: ctaEvent, context };
}
