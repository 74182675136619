const IMG_SWIPE_THRESH = 100; // really sensitive

const IMAGE_SIZES = {
  large: '(max-width: 768px) 100vw, (max-width: 1024px) 57vw, (max-width: 1440px) 60vw, 1684px',
  small: '(max-width: 768px) 100vw, (max-width: 1024px) 57vw, (max-width: 1440px) 30vw, 30vh',
  largest: '(max-width: 768px) 100vw, (max-width: 1024px) 57vw, (max-width: 1440px) 60vw, 1684px',
  review: '(max-width: 768px) 88vw, (max-width: 1024px) 93vw, (max-width: 1440px) 52vw, 1574px',
};

const DOT_DIAMETER_LARGE = 8;
const DOT_DIAMETER_SMALL = 6;

const DEFAULT_TO_INDEX_DOTS = 2;

export {
  IMAGE_SIZES,
  IMG_SWIPE_THRESH,
  DOT_DIAMETER_LARGE,
  DOT_DIAMETER_SMALL,
  DEFAULT_TO_INDEX_DOTS,
};
